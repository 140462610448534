<template>
  <MDialog v-model="modalShow" title="工时导入">
    <div class="batchImport">
      <el-steps :active="stepActive" finish-status="success" process-status="finish">
        <el-step title="上传文件" description="请上传指定格式文件" />
        <el-step title="校验数据" description="检查数据格式是否正确" />
        <el-step title="写入数据" description="正在执行写入数据" />
      </el-steps>
      <!-- 上传文件 -->
      <div v-if="stepActive === 0">
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-tit">下载模板</div>
            <div class="down-des">点击下方文字按钮，下载模版，并按照规定格式填写数据，每次填写数据总和不超过10000条</div>
            <div class="down-btn" @click="downloadTemplate">
              <img src="@/assets/l_c_M_images/download.png" class="icon">
              <span class="download">下载模板</span>
            </div>
          </div>
        </div>
        <div class="down-template">
          <div class="down-le" />
          <div class="down-ri">
            <div class="down-tit">上传文件<el-checkbox v-if="showCover" v-model="cover" :true-label="1" :false-label="0" style="margin-left: 10px">{{coverName}}</el-checkbox></div>
            <div class="down-des">上传文件格式仅支持xls，xlsx，且文件大小不得超过5M</div>
            <div class="down-btn">
              <el-upload ref="upload" :file-list="fileList" v-bind="uploadProps">
                <el-button type="text" class="icons" icon="el-icon-upload" style="padding: 0">上传文件</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <!-- 校验数据 -->
      <div v-if="stepActive === 1">
        <template v-if="!loading">
          <!-- 校验成功 -->
          <div v-if="tableData && !tableData.length" class="check-success">
            <div class="suc-top">
              <div class="tit">校验数据</div>
              <div class="des">共导入数据 {{ total }} 条，其中有 0 条数据错误</div>
            </div>
            <img src="https://oss.mubyte.cn/static/import-success.png" class="imgs">
            <div class="check-tip">数据格式正确，无错误数据</div>
          </div>
          <!-- 校验失败 -->
          <div v-else class="check-error">
            <div class="err-top">
              <div class="tit">校验数据</div>
              <div v-if="tableData" class="des">共导入数据 {{ total }} 条，其中<span
                class="font-color font-wight"
              >有 {{ tableData.length }} 条数据错误</span>
                ，选择【导入】导入 <span style="color: #00AB29">{{ total - tableData.length }}</span> 条正确数据或修改后【重新上传】
              </div>
              <div v-else class="des">
                <span class="font-color font-wight">校验失败，请联系管理员或重新上传文件</span>
              </div>
            </div>
            <MTable
              v-if="tableData"
              ref="mTable"
              :columns="columns"
              :height="height"
              :data="tableData"
              :show-page="false"
              :cell-class-name="cellClassName"
            >
              <template v-for="column in columns" :slot="column.slotName" slot-scope="{ row }">
                <div :key="column.slotName">
                  <template v-if="column.slotName === 'reason'">
                    <div v-for="(reason, index) in row.reason.split(',')" :key="index" class="font-color">{{ reason }}</div>
                  </template>
                  <div v-else>
                    <span>{{ row[column.prop].split(':')[0] }}</span>
                    <span v-if="row[column.prop].split(':')[1]" class="font-color" style="margin-left: 5px">[{{ row[column.prop].split(':')[1] }}]</span>
                  </div>
                </div>
              </template>
            </MTable>
          </div>
        </template>
        <div
          v-else
          v-loading="loading"
          class="loading-placeholder"
          element-loading-text="校验中"
          :style="{ height: loadingHeight + 'px' }"
        />
      </div>
      <!-- 写入数据 -->
      <div v-if="stepActive === 2">
        <img src="https://oss.mubyte.cn/static/import-ing.png" class="img-suc">
        <el-progress :percentage="percentage" class="suc-tit" />
        <div v-if="percentage > 0" class="check-tips">{{ 100 > percentage ? '正在导入中…' : '导入成功' }}</div>
        <div v-else class="check-tips font-color">导入失败，请重新上传或联系管理员</div>
      </div>
      <div v-if="stepActive === 3">
        <img src="https://oss.mubyte.cn/static/data-success.png" class="img-suc">
        <div class="suc-tit">数据导入成功</div>
        <div class="check-tips">成功导入数据数量{{ total }}条，点击完成</div>
      </div>
    </div>
    <div slot="footer" class="mubai-dialog-footer center-content">
      <el-button v-if="stepActive !== 2" @click="cancel">{{ cancelText[stepActive] }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="uploading" @click="submitForm">{{ confirmTextFun() }}</el-button>
    </div>
  </MDialog>
</template>
<script>
  import { export_json_to_excel } from '@/utils/plug/to-excel'
  import { getToken } from '@/utils/auth'
  import processApi from '@/api/information/production/process'

  export default {
    name: 'BatchImport',
    props: {
      visible: {
        type: Boolean,
        default: true
      },
      importName: {
        type: String,
        default: 'processFlowStandardHoursImport'
      },
      coverName:{
        type: String,
        default: ''
      },
      showCover: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        modalShow: false,
        stepActive: 0,
        total: 0,
        columns: [],
        tableData: [],
        fileList: [],
        loading: false,
        submitLoading: false,
        excelContent: [],
        cancelText: {
          0: '取 消',
          1: '上一步',
          2: '关 闭'
        },
        confirmText: {
          0: '校 验',
          1: '导 入',
          2: '查 看'
        },
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/checkExceFile`,
          headers: { token: getToken() },
          showFileList: true,
          limit: 1,
          autoUpload: false,
          onSuccess: this.fileUploadChange,
          onRemove: this.fileRemove,
          onError: this.fileUploadChange,
          onExceed: this.fileExceed,
          onChange: this.fileUploadChange,
          beforeUpload: this.beforeUpload
        },
        uploading: false,
        percentage: 20,
        timer: '',
        cover: 0
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
      },
      loadingHeight() {
        return 270
        // return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 77
      }
    },
    watch: {
      visible(val) {
        this.modalShow = val
        if (val) {
          this.stepActive = 0
          this.percentage = 20
          this.total = 0
          this.loading = false
          this.uploading = false
          this.tableData = []
          this.fileList = []
        }
      },
      modalShow(val) {
        if (!val) {
          this.$emit('update:visible', false)
        }
      }
    },
    mounted() {
      this.modalShow = this.visible
    },
    methods: {
      confirmTextFun() {
        const text = !this.uploading ? this.confirmText[this.stepActive] : '导入中...'
        return this.percentage === 0 ? '重新上传' : text
      },
      cellClassName({ row, column }) {
        return column.property !== 'reason' && row[column.property] && row[column.property].split(':')[1] ? 'has-error' : ''
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5M!')
        }
        return isLt5M
      },
      fileExceed() {
        this.$message.info('只能上传一个文件')
      },
      fileRemove() {
        this.fileList = []
      },
      // 上传图纸/附件
      fileUploadChange(res, file) {
        if (res.raw) {
          this.fileList = file
          return true
        }
        if (res.errorCode === 0) {
          return this.$message.success('上传成功')
        }
        if (res.errorCode !== 0) {
          this.fileList = []
          return this.$message.error(res.result)
        }
      },
      async downloadTemplate() {
        const res = await processApi.exportProcessFlowStandardHours({downloadTemplate : true})
        if (res && res.data.byteLength) {
          const urlP = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/octet-stream;charset=ISO8859-1'
            })
          )
          const fileName = `工时导入模板.xlsx`
          this.downloadFile(urlP, fileName)
        }
      },
      downloadFile(urlP, name) {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = urlP
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      async checkFile() {
        this.loading = true
        const formData = new FormData()
        formData.set('file', this.fileList[0].raw)
        let res = await processApi.importCheckProcessFlowStandardHours(formData)
        this.total = res && res.totalCount
        if (res && res.errorCount > 0) {
          let indexs = 1
          let tableIndex = 2
          this.excelContent = res.errorMap[0]
          // 设置表头
          this.columns = res.errorMap[indexs].map((item, index) => (index !== 0 ? {
            prop: item,
            label: item,
            sortable: false,
            hiddenSearch: true,
            slotName: item
          } : {
            prop: 'reason',
            label: item,
            sortable: false,
            hiddenSearch: true,
            fixed: 'left',
            slotName: 'reason'
          }))
          // 格式化表体数据
          this.tableData = Object.values(res.errorMap)
            .slice(tableIndex, res.errorCount + tableIndex)
            .map((item, index) => {
              const obj = {}
              const reasonObj = {}
              // 将原因格式化以填充到每个单元格
              item[0].split(',').forEach(reason => {
                const reasons = reason.split(':')
                reasonObj[reasons[0]] = reasons[1]
              })
              item.forEach((child, childIndex) => {
                const columnProp = this.columns[childIndex].prop
                obj.id = index
                obj[columnProp] = reasonObj[columnProp] ? `${child}:${reasonObj[columnProp]}` : child
              })
              return obj
            })
          this.exportExcel()
        }
        if (!res) {
          this.tableData = false
        }
        this.loading = false
      },
      // 导出错误文件Excel
      exportExcel() {
        const merges = [0, 0, 0, this.columns.length]
        const params = {
          key: this.columns.map(item => item.prop),
          header: this.columns.map(item => item.label),
          content: this.excelContent,
          merges,
          data: this.tableData,
          filename: '错误数据'
        }
        export_json_to_excel(params)
      },
      async submitForm() {
        if (this.fileList.length !== 1) {
          this.submitLoading = false
          return this.$message.info('请先上传文件')
        }
        if (this.tableData === false) {
          return this.$message.info('文件校验失败，请重新上传文件')
        }
        if (this.stepActive === 1 && this.total - this.tableData.length === 0) {
          return this.$message.info('不存在正确数据可导入，请重新上传文件')
        }
        if (this.percentage === 0) {
          this.stepActive = 0
          this.percentage = 20
          return false
        }
        if (this.stepActive === 0) {
          // 校验
          await this.$refs.upload.submit()
          this.submitLoading = false
          this.stepActive = 1
          await this.checkFile()
          return true
        }
        if (this.stepActive === 1) {
          // 导入
          await this.importExcel()
          return true
        }
        // 导入成功重新刷新
        if (this.stepActive === 2) {
          window.location.reload()
        }
      },
      async importExcel() {
        this.percentage = 20
        this.stepActive = 2
        this.uploading = true
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          if (this.percentage + 5 >= 100) {
            this.percentage = 98
          } else {
            this.percentage += 5
          }
        }, 500)
        const formData = new FormData()
        formData.set('file', this.fileList[0].raw)
        let res = await processApi.importProcessFlowStandardHours(formData)
        if (res) {
          clearInterval(this.timer)
          this.percentage = 100
          this.uploading = false
        } else {
          this.percentage = 0
          this.uploading = false
          clearInterval(this.timer)
        }
      },
      cancel() {
        if (this.stepActive === 0) {
          this.modalShow = false
          return true
        }
        if (this.stepActive === 1) {
          this.stepActive = 0
          this.loading = false
          this.tableData = []
          return true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .batchImport {
    padding: 10px;

    .down-template {
      background: #F6F7F9;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      margin: 30px 0 20px 0;

      .down-le {
        width: 6px;
        background: #607FFF;
        border-radius: 4px 0px 0px 4px;
        margin-right: 20px;
        padding: 20px 0;
      }

      .down-ri {
        padding: 20px 0;

        .down-tit {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #545982;
          line-height: 20px;
          margin-bottom: 2px;
        }

        .down-des {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9FA1B7;
          line-height: 20px;
        }

        .down-btn {
          margin-top: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            width: 21px;
            height: 15px;
            color: #607FFF;;
          }

          .download {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #607FFF;
            line-height: 20px;
            margin-left: 8px;
          }

          .icons {
            color: #607FFF;
            display: inline-flex;
            align-items: center;

            ::v-deep .el-icon-upload {
              font-size: 22px;
              color: #607FFF;
            }
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0091FF;
            line-height: 20px;
            margin-left: 8px;
          }
        }
      }
    }

    .check-success {
      margin-top: 30px;

      .suc-top {
        background: rgba(0, 171, 41, 0.02);
        border-radius: 4px;
        border: 1px solid #00AB29;
        padding: 20px 0 20px 25px;

        .tit {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #545982;
          line-height: 22px;
        }

        .des {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A9ABBF;
          line-height: 22px;
        }
      }

      .imgs {
        width: 264px;
        height: 166px;
        margin: 43px auto 7px auto;
        display: block;
      }

      .check-tip {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 20px;
        text-align: center;
      }
    }

    .check-error {
      margin-top: 30px;

      .err-top {
        background: rgba(224, 32, 32, 0.02);
        border-radius: 4px;
        border: 1px solid #E02020;
        padding: 20px 0 20px 25px;
        margin-bottom: 25px;

        .tit {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #545982;
          line-height: 22px;
        }

        .des {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A9ABBF;
          line-height: 22px;
        }
      }

      .font-color {
        color: #f00;
      }

      .font-wight {
        font-weight: 700;
      }

    }

    .suc-tit {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #393D60;
      line-height: 20px;
      text-align: center;
      margin-top: -50px;
    }

    .check-tips {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ABBF;
      line-height: 20px;
      text-align: center;
    }

    .img-suc {
      width: 279px;
      height: 279px;
      margin: 125px auto 7px auto;
      display: block;
    }
  }

  .el-dialog__footer {
    padding: 0 !important;
    height: 57px;
  }

  .mubai-dialog-footer {
    position: absolute;
    height: 57px;
    width: 100%;
    border-top: 1px solid #DDE3EB;
    padding: 10px 20px;
    bottom: 0;
    background: #FFFFFF;

    button {
      padding: 10px 40px !important;

      &:first-child {
        background: #F8F8FB;
        color: #8A8CA5;
        transition: all .5s;

        &:hover {
          border-color: #dadaf6;
          background: #f0f0f8;
        }
      }

      &:last-child {
        background: $primary-color;
        color: #ffffff;
        transition: all .5s;

        &:hover {
          background: #7691fc;
        }
      }
    }
  }

  .center-content {
    text-align: center;
  }
  ::v-deep .has-error {
    background: rgba(224, 32, 32, 0.04);
  }
</style>
