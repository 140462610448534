import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/basicData/production/private/processFlow/'
const baseUrl1 = 'api/web/v1/basicData/production/private/processFlowClass/'
const baseUrl2 = 'api/web/v1/basicData/production/private/processFlowStandardHours/'

export default {
  // 获取区域列表-- 不分页
  getAreaList(params) {
    return request.get('api/web/v1/basicData/private/area/getAreaList', params)
  },
  // 获取工艺-不分页
  getProcessList(data) {
    return request.get(baseUrl + 'getProcessFlowList', data)
  },
  // 获取工艺-分页
  getProcessListPage(data) {
    return request.get(baseUrl + 'getProcessFlowListPage', data)
  },
  // 新增工艺
  addProcess(data) {
    return request.post(baseUrl + 'addProcessFlow', data)
  },
  // 新增工艺版本
  addProcessVersion(data) {
    return request.post(baseUrl + 'addProcessFlowVersion', data)
  },
  // 修改工艺
  modifyProcess(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 复制工艺
  copyProcess(data) {
    return request.post(baseUrl + 'addProcessFlow', data)
  },
  // 删除工艺
  delProcess(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  // 工艺详情
  getProcessDetail(params) {
    return request.get(baseUrl + 'getProcessFlowDetail', params)
  },
  // 获取版本名称
  getVersionName(id) {
    return request.get(baseUrl + 'generateNewVersion', { processFlowId: id })
  },
  // 删除工艺版本
  delProcessVersion(id) {
    return request.del(baseUrl + 'deleteVersion', { id })
  },
  // 重命名版本
  replaceProcessVersionName(params) {
    return request.get(baseUrl + 'reNameVersion', params)
  },
  // 设为主版本
  setMainVersion(id) {
    return request.del(baseUrl + 'setVersionToMain', { id })
  },
  // 获取工序顺序
  getProcedureList(data) {
    return request.post(baseUrl + 'getProcedureList', data)
  },
  // 获取工艺路线类型
  getProcedureTypeList(data) {
    return request.get(baseUrl1 + 'getProcessFlowClassList', data)
  },
  // 新增工艺路线类型
  addProcessType(data) {
    return request.post(baseUrl1 + 'addProcessFlowClass', data)
  },
  // 修改工艺路线类型
  modifyProcessType(data) {
    return request.put(baseUrl1 + 'updateOne', data)
  },
  // 删除工艺路线类型
  delProcessType(id) {
    return request.del(baseUrl1 + 'deleteOne', { id })
  },
  // 获取ERP仓库
  getErpWarehouseList() {
    return request.get('api/web/v1/updateInterface/private/getWarehouseList', {}, true)
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxProcessFlowCode', params)
  },
  // 获取成品物料列表
  getFinishedMaterials(params = {}) {
    return request.get('api/web/v1/basicData/materials/private/materials/getFinishedMaterials', params)
  },
  // 模组工单获取成品物料列表
  getModulesMaterials(params = {}) {
    return request.get('api/web/v1/production/private/productionPlan/getUpWorkOrderList', params)
  },
  // 获取用户列表
  getUserList(params = {}){
    return request.get('api/web/v1/authority/private/user/listAllUsersByPage', params)
  },
  // 获取BOM图表信息
  getBomRelationView(params) {
    return request.get('api/web/v1/basicData/production/private/processFlow/getBomRelationView', params)
  },
  // 获取BOM列表
  getBomListView(params) {
    return request.get('api/web/v1/basicData/production/private/processFlow/getBomListView', params)
  },
  // 批量删除工艺路线
  batchDelete(params) {
    return request.post(baseUrl + 'batchDelete', params)
  },
  // 根据codes获取物料信息
 getMaterialsListByCode(params) {
    return request.post('api/web/v1/basicData/materials/private/materials/getMaterialsListByCode', params)
  },
  // 获取项目下拉分页列表
  getProjectListPage(params = {}) {
    return request.get('api/web/v1/production/private/project/pullDownListPage', params)
  },
    // 获取项目下拉不分页列表
    getProjectList(params = {}) {
      return request.get('api/web/v1/production/private/project/getProjectProductionNodeList', params)
    },
  // 获取客户下拉列表
  getCustomerListPage(params = {}){
    return request.get('api/web/v1/basicData/private/customer/pullDownListPage', params)
  },
  synchronizedWorkOrder(params) {
    return request.post('api/web/v1/production/private/productionPlan/synchronized', params)
  },
  //修改bom
  modifyBOMInfo(params){
    return request.post(baseUrl + 'updateBomListView', params)
  },
   // 获取工艺路线对应版本信息
   getProcessFlowVersionDetail(params) {
    return request.get('api/web/v1/basicData/production/private/processFlowVersion/getProcessFlowVersionDetail', params)
  },
  // 获取物料分类列表
  getMaterialsClassList(data) {
    return request.get('api/web/v1/basicData/materials/private/materialsClass/getMaterialsClassList', data)
  },
  // 导出工艺路线标准工时
  exportProcessFlowStandardHours(data) {
    return request.exportPost(baseUrl2 + 'export', data)
  },
  // 标准工时导入前校验
  importCheckProcessFlowStandardHours(data) {
    return request.post(baseUrl2 + 'importCheck', data)
  },
  // 标准工时数据导入
  importProcessFlowStandardHours(data) {
    return request.post(baseUrl2 + 'import', data)
  },
  // 工艺路线标准工时列表
  getProcessFlowStandardHoursListPage(data) {
    return request.get(baseUrl2 + 'getProcessFlowStandardHoursListPage', data)
  },
  // 物料工艺路线列表
  getMaterialsProcessFlowListPage(data) {
    return request.get(baseUrl + 'getMaterialsProcessFlowListPage', data)
  },
  // 计划订单下拉列表
  getPlanOrderPullDownList(data){
    return request.get('api/web/v1/production/private/planOrder/getPlanOrderPullDownList', data)
  }
}
