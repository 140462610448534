<template>
  <div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入工序定位码/编码/名称"
      :tags="functionHeader.tags"
      :columns-setting="false"
      @search="$refs.standardHoursTable.setTableData(true)"
    />
    <MTable
      ref="standardHoursTable"
      :show-page="true"
      :columns="getColumns"
      :columns-setting="false"
      :set-data-method="getProcessFlowStandardHoursListPage"
      style="padding-bottom: 20px;"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
    </MTable>
  </div>

</template>
<script>
  import FunctionHeader from '@/components/FunctionHeader'
  import api from '@/api/information/production/process'
  import { StandardHoursColumns } from './columns'

  export default {
    name: 'StandardHours',
    components: { FunctionHeader },
    props: {
      processFlowVersion: {
        type: Object,
        default: () => ({})
      },
      baseFormData: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        StandardHoursColumns,
        standardHoursList: [],
        total: 0,
        functionHeader: {
          searchVal: '',
          tags: []
        }
      }
    },
    watch: {
      'processFlowVersion.id': {
        immediate: false,
        handler(val) {
          val && this.init()
        }
      }
    },
    computed: {
    getColumns() {
      if (this.baseFormData.processFlowClassName != '物料') {
        return this.StandardHoursColumns
      } else {
        return this.StandardHoursColumns.filter(item => {
          return item.prop !== 'materialsCode' && item.prop !== 'materialsName'
        })
      }
    }
  },
    created() {},
    beforeDestroy() {},
    methods: {
      async init() {
        this.$refs.standardHoursTable.setTableData(true)
      },

      async getProcessFlowStandardHoursListPage(condition, callback){
        if(!this.processFlowVersion.id){
          return
        }
        const { page } = condition
        const res = await api.getProcessFlowStandardHoursListPage({
            page: page.currentPage,
            limit: page.pageSize,
            processFlowVersionId: this.processFlowVersion.id,
            searchKeyword: this.functionHeader.searchVal
          })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      }
    }
  }
</script>
