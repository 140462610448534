var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FunctionHeader", {
        ref: "functionHeader",
        attrs: {
          "search-title": "请输入工序定位码/编码/名称",
          tags: _vm.functionHeader.tags,
          "columns-setting": false
        },
        on: {
          search: function($event) {
            return _vm.$refs.standardHoursTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "standardHoursTable",
        staticStyle: { "padding-bottom": "20px" },
        attrs: {
          "show-page": true,
          columns: _vm.getColumns,
          "columns-setting": false,
          "set-data-method": _vm.getProcessFlowStandardHoursListPage
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }