import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/tool/private/tool/'

export default {
  // 工具列表
  getToolLedgerListPage(data) {
    return request.get(baseUrl + 'getToolListPage', data)
  },
  // 获取工具详情
  getToolLedgerDetail(data) {
    return request.get(`${baseUrl}getToolDetail`, data)
  },
  // 工具新增
  add(data) {
    return request.post(baseUrl + 'addTool', data)
  },
  // 工具编辑
  edit(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 工具删除
  del(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  /**
   * 焊工资格
   * */
  getWelderQualificationListPage(data) {
    return request.get('api/web/v1/basicData/weld/private/welderQualification/getWelderQualificationListPage', data)
  },
  addWelderQualification(data){
    return request.post('api/web/v1/basicData/weld/private/welderQualification/addWelderQualification', data)
  },
  editWelderQualification(data){
    return request.put('api/web/v1/basicData/weld/private/welderQualification/updateOne', data)
  },
  delWelderQualification(id){
    return request.del('api/web/v1/basicData/weld/private/welderQualification/deleteOne?id=' + id)
  },
  getWorkOrder(data){
    return request.post('api/web/v1/production/private/productionPlan/list',data)
  },
  /**
   * 合格项目  welderProject
   * */
  getWelderProjectListPage(data){
    return request.get('api/web/v1/basicData/weld/private/welderProject/getWelderProjectListPage?page='+ data.page +'&limit=' + data.size)
  },
  /**
   * 焊工编号  welder
   * */
  getWelderListPage(data){
    return request.get('api/web/v1/basicData/weld/private/welder/getWelderListPage?page='+ data.page +'&limit=' + data.size)
  },
  /**
   * 焊接工艺规程
   * */
  getWeldProcessRegulationListPage(data){
    return request.get('api/web/v1/basicData/weld/private/weldProcessRegulation/getWeldProcessRegulationListPage',data)
  },
  addWeldProcessRegulation(data){
    return request.post('api/web/v1/basicData/weld/private/weldProcessRegulation/addWeldProcessRegulation',data)
  },
  editWeldProcessRegulation(data){
    return request.put('api/web/v1/basicData/weld/private/weldProcessRegulation/updateOne',data)
  },
  delWeldProcessRegulation(id){
    return request.del('api/web/v1/basicData/weld/private/weldProcessRegulation/deleteOne?id='+id)
  }
}
