import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/purchase/private/'

export default {
  // 获取列表
  getSalesOrderList(data) {
    return request.get(baseUrl + 'purchaseOrder/list', data)
  },
  // 创建人/更新人
  getUsers(data) {
    return request.get(baseUrl + 'purchaseOrder/users', data)
  },
  // 用户下拉列表
  users(data) {
    return request.get(baseUrl + 'purchaseOrder/users', data)
  },
  // 供应商选项
  supplierCandidates(data) {
    return request.get(baseUrl + 'purchaseOrder/supplierCandidates', data)
  },
  // 查看详情
  viewDetail(data) {
    return request.get(baseUrl + 'purchaseOrder/detail', data)
  },
  // 新建
  addPurchaseOrder(data) {
    return request.post(baseUrl + 'purchaseOrder/create', data)
  },
  // 修改
  updatePurchaseOrder(data) {
    return request.put(baseUrl + 'purchaseOrder/update', data)
  },
  // 删除
  delete(data) {
    return request.put(baseUrl + 'purchaseOrder/delete', data)
  },
  // 下发
  commit(data) {
    return request.put(baseUrl + 'purchaseOrder/commit', data)
  },
  // 完结
  finish(data) {
    return request.put(baseUrl + 'purchaseOrder/complete', data)
  },
  // 取消
  cancel(data) {
    return request.put(baseUrl + 'purchaseOrder/cancel', data)
  },
  // 导出
  export(data) {
    return request.exportGet(baseUrl + 'purchaseOrder/export', data)
  }
}
