import request from '@/utils/requestForAsync'

const baseUrl = 'api/web/v1/'

export default {
  // 导出模板
  exportTemplate(url) {
    return request.exportGet(baseUrl + url)
  },
  // 导出物料单位
  exportMaterialsUnit(data) {
    return request.exportGet(baseUrl + 'basicData/materials/private/materialsUnit/export', data)
  },
  // 导出物料
  exportMaterials(data) {
    return request.exportGet(baseUrl + 'basicData/materials/private/materials/export', data)
  },
  // 导出供应商
  exportSupplier(data) {
    return request.exportGet(baseUrl + 'basicData/private/supplier/export', data)
  },
  // 导出客户
  exportCustomer(data) {
    return request.exportGet(baseUrl + 'basicData/private/customer/export', data)
  },
  // 导出仓库建模
  exportWarehouseModel(data) {
    return request.exportGet(baseUrl + 'warehouse/private/warehouse/export', data)
  },
  // 导出包装类
  exportPackage(data) {
    return request.exportGet(baseUrl + 'warehouse/private/containerClass/export', data)
  },
  // 导出托盘
  exportTray(data) {
    return request.exportGet(baseUrl + 'warehouse/private/pallet/export', data)
  },
  // 导出仓库总表
  // exportInventory(data) {
  //   return request.exportGet(baseUrl + 'warehouse/kiki/private/warehouseMaterialsBatKiki/exportWarehouseMaterialsTotal?isShowZero=1&type=', data)
  // },
  exportInventory(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/warehouseMaterialsBatKiki/exportWarehouseMaterialsTotal', data)
  },
  // 导出库存明细表
  exportInventoryDetail(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/warehouseMaterialsBatKiki/exportWarehouseMaterialsDetails', data)
  },
  // 导出物料汇总表
  exportMaterialsInventory(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/warehouseMaterialsBatKiki/exportMaterialsInventoryListTotal', data)
  },
  // 导出批次汇总表
  exportBatch(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/warehouseMaterialsBatKiki/exportgetBatchSummary', data)
  },
  // 导出废品
  exportScrap(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/wasteWarehouse/export', data)
  },
  // 导出质检记录
  exportQualityRecord(data) {
    return request.exportGet(baseUrl + 'quality.kiki/private/qualityInspectionRecordKiki/export', data)
  },
  // 导出工序参数
  exportProcedureParam(data) {
    return request.exportGet(baseUrl + 'basicData/settings/private/procedureParam/export', data)
  },
  // 导出报废原因
  exportWasteReason(data) {
    return request.exportGet(baseUrl + 'basicData/settings/private/wasteReason/export', data)
  },
  // 导出用户
  exportUser(data) {
    return request.exportGet(baseUrl + 'authority/private/user/export', data)
  },
  // 导出用户组
  exportUserGroup(data) {
    return request.exportGet(baseUrl + 'basicData/settings/private/userGroup/export', data)
  },
  // 导出工位
  exportStation(data) {
    return request.exportGet(baseUrl + 'basicData/private/station/export', data)
  },
  // 导出生产订单
  exportOrders(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/produceOrder/export', data)
  },
  // 导出工序
  exportProcedure(data) {
    return request.exportGet(baseUrl + 'basicData/production/private/procedure/export', data)
  },
  // 导出计划
  exportPlan(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/producePlan/export', data)
  },
  // 导出工单
  exportTask(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/produceWorkOrder/export', data)
  },
  // 导出投产记录
  exportReceiveMaterials(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/materialsReceiveRecord/export', data)
  },
  // 导出报工记录
  exportReportWork(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/reportWorkRecord/export', data)
  },
  // 导出线边仓库存-物料
  exportSiteWarehouse(data) {
    return request.exportGet(baseUrl + 'siteWarehouse/kiki/private/sitMaterialsBatKiki/exportStockMaterialsList', data)
  },
  // 导出线边仓库存-包装
  exportStockContainer(data) {
    return request.exportGet(baseUrl + 'siteWarehouse/kiki/private/sitMaterialsBatKiki/exportStockContainerList', data)
  },
  // 导出线边仓库存-托盘
  exportStockPallet(data) {
    return request.exportGet(baseUrl + 'siteWarehouse/kiki/private/sitMaterialsBatKiki/exportStockPalletList', data)
  },
  // 导出工序任务-工单
  exportOperationTask(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/produceWorkOrderTask/export', data)
  },
  // 导出工艺路线
  exportProcess(data) {
    return request.exportGet(baseUrl + 'basicData/production/private/processFlow/export', data)
  },
  // 导出角色
  exportRole(data) {
    return request.exportGet(baseUrl + 'authority/private/role/export', data)
  },
  // 导出班次
  exportShift(data) {
    return request.exportGet(baseUrl + 'authority/private/frequency/export', data)
  },
  // 导出日历
  exportCalendar(data) {
    return request.exportGet(baseUrl + 'basicData/private/calendar/export', data)
  },
  // 导出资源
  exportResource(data) {
    return request.exportGet(baseUrl + 'basicData/private/resource/export', data)
  },
  // 导出工作中心
  exportWorkCenter(data) {
    return request.exportGet(baseUrl + 'basicData/private/workCenter/export', data)
  },
  // 导出设备类
  exportEquipmentClass(data) {
    return request.exportGet(baseUrl + 'basicData/private/equipmentClass/export', data)
  },
  // 导出设备台账
  exportAccount(data) {
    return request.exportGet(baseUrl + 'equipment/private/equipment/export', data)
  },
  // 导出工厂建模
  exportFactoryModel(data) {
    return request.exportGet(baseUrl + 'basicData/private/area/export', data)
  },
  // 导出返修记录
  exportRepairRecord(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/repairRecord/export', data)
  },
  // 导出采购申请
  exportPurchase(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/purchaseRequestDetail/export', data)
  },
  // 导出工序段
  exportProcedureGroup(data) {
    return request.exportGet(baseUrl + 'basicData/production/private/procedureGroup/export', data)
  },
  // 导出维保经验
  exportExperience(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/maintenanceExperience/export', data)
  },
  // 导出故障原因
  exportFaultReason(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/faultReason/export', data)
  },
  // 导出紧急程度
  exportUrgencyLevel(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/emergenceDegree/export', data)
  },
  // 导出设备部位
  exportEquipmentPosition(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/equipmentPart/export', data)
  },
  // 导出点巡检项
  exportPointCheckItem(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/checkItem/export', data)
  },
  // 导出保养项目
  exportMaintenanceProject(data) {
    return request.exportGet(baseUrl + 'equipment/setting/private/maintenanceItem/export', data)
  },
  // 导出备件台账
  exportSparePart(data) {
    return request.exportGet(baseUrl + 'equipment/sparePart/private/sparePart/export', data)
  },
  // 导出备件库存
  exportSparePartNums(data) {
    return request.exportGet(baseUrl + 'equipment/sparePart/warehouse/private/sparePartWarehouseStock/export', data)
  },
  // 导出备件库存流水
  exportSparePartPipeline(data) {
    return request.exportGet(baseUrl + 'equipment/sparePart/warehouse/private/sparePartWarehouseOutInRecord/export', data)
  },
  // 导出物料bom
  exportMaterialsBom(data) {
    return request.exportGet(baseUrl + 'basicData/materials/private/materialsBom/export', data)
  },
  // 导出检验方法
  exportInspectionMethods(data) {
    return request.exportGet(baseUrl + 'inspection/private/inspectionMethod/export', data)
  },
  // 导出检验标准
  exportInspectionStandard(data) {
    return request.exportGet(baseUrl + 'inspection/private/inspectionStandard/export', data)
  },
  // 导出检验条目
  exportInspectionItems(data) {
    return request.exportGet(baseUrl + 'inspection/private/inspectionEntry/export', data)
  },
  // 导出检验项目
  exportInspectionProject(data) {
    return request.exportGet(baseUrl + 'inspection/private/inspectionProject/export', data)
  },
  // 导出缺陷项目
  exportDefectsItem(data) {
    return request.exportGet(baseUrl + 'inspection/private/defectItem/export', data)
  },
  // 导出缺陷原因
  exportDefectsReason(data) {
    return request.exportGet(baseUrl + 'inspection/private/defectReason/export', data)
  },
  // 导出呼叫查询
  exportCallSearch(data) {
    return request.exportGet(baseUrl + 'notification/private/callOut/export', data)
  },
  // 导出外协申请列表
  exportOutSource(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/outsourcingRequest/export', data)
  },
  // 导出报工记录
  exportReportWorkRecord(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/reportWorkRecord/export', data)
  },
  // 导出投产记录
  exportProductionRecord(data) {
    return request.exportGet(baseUrl + 'production/kiki/private/materialsReceiveRecord/export', data)
  },
  // 导出库存流水-出入库记录
  exportInventoryAccount(data) {
    return request.exportGet(baseUrl + 'warehouse/kiki/private/outInWarehouseBillRecord/export', data)
  },
  // 导出线边仓流水
  exportSitWarehouseAccount(data) {
    return request.exportGet(baseUrl + 'siteWarehouse/kiki/private/outInSitWarehouseRecord/export', data)
  },
  // 导出备件申请
  sparePartProcurement(data) {
    return request.exportGet(baseUrl + 'equipment/sparePart/private/sparePartProcurement/export', data)
  },
  // 导出库存操作单据列表
  exportWarehouseOperationSheet(data) {
    return request.exportGet(baseUrl + 'warehouse/record/private/warehouseOperationSheet/export', data)
  },
  // 导出维修工单列表
  exportRepairs(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityRepairWordOrder/export', data)
  },
  // 导出BOM列表
  exportBOMList(params) {
    return request.exportGet(baseUrl + 'basicData/production/private/processFlowBomListView/export', params)
  },
  // 导出Bom列表
  exportBomList2(data) {
    return request.exportPost(baseUrl + 'bom/private/materialsBom/export', data)
  },
  // 齐套列表导出
  exportCalculateListData(data) {
    return request.exportPost(baseUrl + 'basicData/materials/private/materialsCompleteCalculate/exportCalculateListData', data)
  },
  // 导出需求列表
  exportFacilityDemand(data) {
    return request.exportGet(baseUrl + 'field/facility/demand/private/facilityDemand/export', data)
  },
  // 导出需求列表 ----bbelc
  exportFacilityDemandBbelc(data) {
    return request.exportGet(baseUrl + 'field/facility/demand/private/facilityDemand/exportFacilityDemand', data)
  },
  // 导出BOM下阶物料
  exportBomLevelDownMList(data){
    return request.exportGet(baseUrl + 'bom/private/materialsBom/exportBomLevelDownMaterialsList', data)
  },
  // 导出MBOM下阶物料
  exportMBomLevelDownMList(data){
    return request.exportGet(baseUrl + 'mbom/private/materialsMBom/exportMBomLevelDownMaterialsList', data)
  },
  // 导出物料平衡总览
  exportMaterialsBalanceList(data){
    return request.exportGet(baseUrl + 'materials/private/materialsBalance/total/export', data)
  },
  // 导出需求单物料详情-工单物料列表
  exportWorkOrderMaterialsList(data){
    return request.exportGet(baseUrl + 'materials/private/requirementOrder/exportWorkOrderMaterialsList', data)
  },
  // 导出领料单详情-领料单物料清单导出
  exportApplyMaterialsList(data){
    return request.exportGet(baseUrl + 'materials/private/materialsRequisition/exportItemList', data)
  },
  // 导出巡检单列表
  exportInspectionBills(data) {
    return request.exportGet(baseUrl + 'equipment/check/private/equipmentCycleCheckTask/export', data)
  },
  // 导出点检单列表
  exportPointBills(data) {
    return request.exportGet(baseUrl + 'equipment/check/private/equipmentSpotCheckTask/export', data)
  },
  // 导出保养单列表
  exportMaintenanceBills(data) {
    return request.exportGet(baseUrl + 'equipment/maintain/private/equipmentMaintainTask/export', data)
  },
  // 导出报修单列表
  exportEquipmentFault(data) {
    return request.exportGet(baseUrl + 'equipment/fault/private/equipmentRepairTask/export', data)
  },
  // 导出维修单列表
  exportEquipmentRepair(data) {
    return request.exportGet(baseUrl + 'equipment/fault/private/equipmentRepairTask/export', data)
  },
  // 导出设备台账列表
  exportFacility(data) {
    return request.exportGet(baseUrl + 'field/facility/private/facility/export', data)
  },
  // 导出工作日报列表
  exportDailyWorkReport(data) {
    return request.exportGet(baseUrl + 'field/manHour/private/dailyWorkReport/export', data)
  },
  // 导出模具定义列表
  exportMouldDefineReport(data) {
    return request.exportPost(baseUrl + 'mold/clazz/private/export', data)
  },
  // 导出模具库存列表
  exportMouldStockReport(data) {
    return request.exportPost(baseUrl + 'mold/instance/private/export', data)
  },
  // 导出设备保养工单列表
  exportMaintainOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityUpkeepWorkOrder/exportDetail', data)
  },
  // 导出设备点检工单列表
  exportSpotCheckOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityInspectionWorkOrder/exportDetail', data)
  },
  // 导出设备维修工单列表
  exportRepairsOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityRepairWordOrder/exportDetail', data)
  },
  // 导出设备装机工单列表
  exportInstallEquipmentOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityInstallWordOrder/exportDetail', data)
  },
  // 导出设备改造工单列表
  exportReformOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/facilityReformWordOrder/exportDetail', data)
  },
  // 导出设备更换工单列表
  exportReplaceOrder(data) {
    return request.exportGet(baseUrl + 'field/facility/invoices/private/changeWorkOrder/exportDetail', data)
  },
  exportReceivedMaterialsOrderItem(data) {
    return request.exportPost(baseUrl + 'production/private/materialsReceivedOrder/exportMaterialsReceivedOrderItemList', data)
  }
}
