import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/project/'
const baseUrl1 = 'api/web/v1/production/private/'

export default {
    // 获取项目编号
    getProjectCode() {
        return request.get(baseUrl + 'getProjectNumber')
    },
    // 获取项目列表
    getProjectListPage(data) {
        return request.get(baseUrl + 'listPage', data)
    },
    // 获取项目基本信息
    getProjectDetail(data) {
        return request.get(baseUrl + 'getOne', data)
    },
    //   新建项目
    addProject(data) {
        return request.post(baseUrl + 'create', data)
    },
    // 修改项目
    modifyProject(data) {
        return request.put(baseUrl + 'update', data)
    },
    //   删除
    delProject(data) {
        return request.del(baseUrl + 'delete', data)
    },
    //   结案
    closeOut(data) {
        return request.put(baseUrl + `closeout?id=${data}`)
    },
    restart(data) {
      return request.put(baseUrl + `restart?id=${data}`)
    },
    // 获取计划节点列表
    getPlanNodeListPage(data){
        return request.get(baseUrl1 + 'planNode/listPage',data)
    },
    // 新增计划节点
    addPlanNode(data){
        return request.post(baseUrl1 + 'planNode/create',data)
    },
    // 修改计划节点
    modifyPlanNode(data){
        return request.put(baseUrl1 + 'planNode/update',data)
    },
    // 删除计划节点
    delPlanNode(data){
        return request.del(baseUrl1 + 'planNode/delete',data)
    },
    // 新建计划类型
    addPlanType(data){
        return request.post(baseUrl1 + 'planType/create',data)
    },
    // 修改计划类型
    modifyPlanType(data){
        return request.put(baseUrl1 + 'planType/update',data)
    },
    //删除计划类型
    delPlanType(data){
        return request.del(baseUrl1 + 'planType/delete',data)
    },
    // 获取计划类型
    getPlanType(data){
        return request.get(baseUrl1 + 'planType/list',data)
    },
    // 获取项目工单
    getProjectWorkListPage(data){
        return request.get(baseUrl + 'workOrderListPage',data)
    },
    // 新增项目物料列表
    addProjectMaterials(data){
        return request.post(baseUrl1 + 'projectMaterials/create',data)
    },
    //修改项目物料列表
    updateProjectMaterials(data){
        return request.put(baseUrl1 + 'projectMaterials/update',data)
    },
    // 删除项目物料列表
   delProjectMaterials(data){
        return request.del(baseUrl1 + 'projectMaterials/delete',data)
    },
	//获取项目物料列表
    getProjectMaterialsListPage(data){
        return request.get(baseUrl1 + 'projectMaterials/listPage',data)
    },
      // 负责人选项
  getResponsible() {
    return request.get('api/web/v1/authority/private/user/listAllUsers')
  },
  // 业务部门选项
  getBusinessUnitList() {
    return request.get('api/web/v1/authority/private/Organization/getBusinessUnitList')
  },
  // 计划对象选项
  getPlanObjectList(data) {
    return request.get(baseUrl1 + 'planNode/getPlanObjectList',data)
  },
//  调整计划
 adjustPlan(data){
    return request.post(baseUrl1 + 'planNode/adjust',data)
 },
//  获取计划时间
getPlanNodeTimeList(data){
   return request.get(baseUrl1 + 'planNode/getPlanNodeTimeList',data)
},
// 获取计划甘特图项目列表
getGunterProjectListPage(data){
    return request.post(baseUrl + 'gunterChart/projectListPage',data)
},
// 获取计划状态数量
getGunterProjectCount(data){
    return request.post(baseUrl + 'gunterChart/projectCount',data)
},
// 获取计划名称列表
getPlanNameList(data){
    return request.get(baseUrl1 + 'planNode/getPlanNameList',data)
},
// 批量修改项目计划节点
batchModifyPlanNode(data){
    return request.post(baseUrl1 + 'planNode/batchUpdate',data)
},
// 批量调整项目计划节点
batchAdjustPlanNode(data){
    return request.post(baseUrl1 + 'planNode/batchAdjust',data)
},
// 获取项目异常列表
getProjectAbnormalListPage(data){
    return request.post(baseUrl + 'getProjectAbnormalListPage',data)
},
// 获取项目ECN列表
getProjectECNListPage(data){
    return request.post(baseUrl + 'getProjectECNListPage',data)
},
// 获取项目物料异常列表
getProjectMaterialsDifferenceListPage(data){
    return request.get(baseUrl + 'getProjectMaterialsDifferenceListPage',data)
},
// 获取项目下发任务列表
getProjectTaskDispatchListPage(data){
  return request.post(baseUrl1 + 'planNode/dispatchListPage',data)
},
// 获取项目任务详情
getProjectTaskDetail(data){
  return request.get(baseUrl1 + 'planNode/detail',data)
},
//任务节点下发，开始，完成
projectTaskChangeStatus(data){
  return request.post(baseUrl1 + 'planNode/changeStatus',data)
},
// 获取任务汇报记录
getPlanNodeReportListPage(data){
  return request.get(baseUrl1 + 'planNodeReport/listPage',data)
},
// 添加任务汇报
addPlanNodeReport(data){
  return request.post(baseUrl1 + 'planNodeReport/create',data)
},
// 删除任务汇报记录
delPlanNodeReport(data){
  return request.del(baseUrl1 + `planNodeReport/delete?id=${data}`)
},
// 汇报人列表
getReportUserList() {
  return request.get('api/web/v1/authority/private/user/listAllUsers')
},
// 获取项目关联需求
getProjectRelateRequirementListPage(data){
    return request.get(baseUrl1 + 'projectRelateRequirement/listPage',data)
},
// 获取项目工时统计列表
getWorkHourStatisticsListPage(data){
    return request.post(`api/web/v1/production/private/workHoursStatistics/list`,data)
},
// 获取项目用料记录列表
getMaterialRecordsListPage(data){
    return request.get('api/web/v1/materials/private/materialsRequisition/listPage', data)
},
// 获取项目齐套分析列表
getCompleteAnalysisListPage(data){
    return request.get('api/web/v1/materials/private/materialsBalance/total/listPage', data)
},
// 获取项目汇报记录列表
getProjectReportRecordsListPage(data){
  return request.get('api/web/v1/production/private/planNodeReport/listPage', data)
},
// 获取节点操作日志
getAdjustRecordListPage(data){
    return request.get('api/web/v1/production/private/planNode/adjustRecordListPage', data)
},
// 催办任务节点
planNodeUrging(data){
    return request.put(`api/web/v1/production/private/planNode/planNodeUrging?id=${data}`)
},
// 获取投料记录
getInputMaterialsRecordListPage(data){
  return request.get('api/web/v1/production/private/productionPlan/getInputMaterialsRecordListPage', data)
}
}
