import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/demand/private/facilityDemand/'
const baseUrl1 = 'api/web/v1/field/facility/demand/private/facilityDemandDispatch/'
const baseUrl2 = 'api/web/v1/field/facility/invoices/private/facilityRepairWordOrder/'
const baseUrl3 = 'api/web/v1/field/facility/demand/private/facilityDemandBusinessPlans/'
const baseUrl4 = 'api/web/v1/field/facility/demand/private/facilityDemandAssemble/'
const applicationUrl = 'api/web/v1/field/facility/demand/private/facilityDemandApplication/'

export default {
  // 获取需求列表（不分页）
  getFacilityDemandList(data) {
    return request.get(baseUrl + 'getFacilityDemandList', data)
  },
  // 获取需求列表（分页）
  getFacilityDemandListPage(data) {
    data.isWeb = 1;
    return request.get(baseUrl + 'getFacilityDemandListPage', data)
  },
  // 获取所有需求列表（分页）
  getAllFacilityDemandListPage(data) {
    return request.get(baseUrl + 'getAllFacilityDemandListPage', data)
  },
  // 新建需求
  addFacilityDemand(data) {
    return request.post(baseUrl + 'addFacilityDemand', data)
  },
  // 新建需求并派发
  addFacilityDemandAndAssignWork(data) {
    return request.post(baseUrl + 'addFacilityDemandAndAssignWork', data)
  },
  // 修改需求
  modifyFacilityDemand(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  addFacilityAttachments(data) {
    return request.post('api/web/v1/field/facility/demand/private/facilityDemandAttachments/addFacilityDemandAttachmentsList', data)
  },
  // 删除设备需求
  delFacilityDemand(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取设备需求-详情
  getFacilityDemandDetail(id) {
    return request.get(baseUrl + 'getFacilityDemandDetail', { id })
  },
  // 派工
  addFacilityDemandDispatch(data) {
    return request.post(baseUrl1 + 'addFacilityDemandDispatch', data)
  },
  // 转交
  sendOnFacilityDemandDispatch(data) {
    return request.post(baseUrl1 + 'sendOnFacilityDemandDispatch', data)
  },
  // 获取工单-需求id
  getFacilityWordOrderListById(id) {
    return request.get(baseUrl2 + 'getFacilityWordOrderList', { id })
  },
  // 添加工单
  addFacilityWordOrder(data) {
    return request.post(baseUrl2 + 'addWordOrder', data)
  },
  // 移除工单
  removeFacilityWordOrder(data) {
    return request.post(baseUrl2 + 'removeWordOrder', data)
  },
  // 删除工单
  deleteFacilityWordOrderOne(data) {
    return request.post(baseUrl2 + 'deleteWordOrderOne', data)
  },
  // 获取维修列表（分页）-未关联需求
  getFacilityRepairWordOrderListPage(data) {
    return request.get(baseUrl2 + 'getFacilityRepairWordOrderListPage', data)
  },
  // 所有的操作
  modifyFacilityDemandOperate(data) {
    return request.post(baseUrl + 'facilityDemandOperate', data)
  },
  // 新建出差计划 - 填写出差计划；修改出差计划
  addFacilityDemandBusinessPlans(data) {
    return request.post(baseUrl3 + 'addFacilityDemandBusinessPlans', data)
  },
  // 获取出差计划详情-需求id
  getFacilityDemandBusinessPlans(id) {
    return request.get(baseUrl3 + 'getFacilityDemandBusinessPlansDetail', { id })
  },
  // 获取出差计划详情-计划id
  getFacilityDemandBusinessPlansDetail(id) {
    return request.get(baseUrl3 + 'getFacilityDemandBusinessPlansDetailById', { id })
  },
  // 获取不同状态需求数量
  getFacilityDemandCount(id) {
    return request.get(baseUrl + 'getFacilityDemandCount')
  },
  // 装机记录修改
  modifyFacilityDemandAssemble(data) {
    return request.put(baseUrl4 + 'updateOne', data)
  },
  // 新建改造流程 - 本地开发申请表
  addFacilityDemandApplication(data) {
    return request.post(applicationUrl + 'addFacilityDemandApplication', data)
  },
  // 获取改造流程 - 本地开发申请表详情
  getFacilityDemandApplicationDetail(id) {
    return request.get(applicationUrl + 'getFacilityDemandApplicationDetail', { id })
  },
  // 修改改造流程 - 本地开发申请表
  modifyFacilityDemandApplication(data) {
    return request.put(applicationUrl + 'updateOne', data)
  },
  // 需求id获取上传文件
  getFacilityDemandAttachmentsList(data) {
    return request.get('api/web/v1/field/facility/demand/private/facilityDemandAttachments/getFacilityDemandAttachmentsListByDemandId', data)
  },
  // 删除技术方案
  delDemandAttachments(data) {
    return request.del('api/web/v1/field/facility/demand/private/facilityDemandAttachments/deleteOne', data)
  },
  // 删除技开发申请表
  delDemandApplication(data) {
    return request.del(applicationUrl + 'deleteOne', data)
  }
}
